import Repository from "./Repository";

const SEND = "send";
const SEND_WITH_ATTACHMENT = "send-with-attachment";

export default {
  sendEmail(payload) {
    return Repository.post(transformRoute(SEND), payload);
  },

  sendEmailWithAttachment(payload) {
    return Repository.post(transformRoute(SEND_WITH_ATTACHMENT), payload);
  },
};

const transformRoute = (route) => {
  return `/email/${route}`;
};
