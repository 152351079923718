import auth from "./auth";
import feedbacks from "./feedbacks";
import email from "./email";
import cms from "./cms";
import menu from "./menu";
import brandProfile from "./brandProfile";
export default {
  auth: auth,
  feedbacks,
  email,
  cms,
  menu,
  brandProfile,
};
