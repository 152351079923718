import state from "./state";

export default {
  SET_Startup(state, payload) {
    state.startup = payload;
  },
  CATEGORY_DETAILS(state, payload) {
    state.category_details = payload;
  },
  INVESTMENT_ADDED(state, payload) {
    state.investment_status = payload;
  },
  SET_Startupbyid(state, payload) {
    state.startupbyid = payload;
  },
  DELETE_CATEGORY(state, payload) {
    const index = state.category.findIndex((page) => page._id == payload._id);
    state.category.splice(index, 1);
  },
};
