import Vue from "vue";
import VueRouter from "vue-router";
import AdminRouter from "@/admin/routes/Index.js";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",

      children: [
        ...AdminRouter,

        {
          path: "/admin-login",
          name: "login",
          component: () => import("@/login/Login.vue"),
        },
        {
          path: "home",
          name: "home",
          component: () => import("@/views/home/Home.vue"),
          meta: {
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "terms-conditions",
          name: "terms-conditions",
          component: () => import("@/views/terms-condition/Terms.vue"),
        },
        {
          path: "privacy-policy",
          name: "privacy-policy",
          component: () => import("@/views/privacy-policy/Privacy.vue"),
        },
        {
          path: "cancellations-refund",
          name: "cancellations-refund",
          component: () =>
            import("@/views/cancellations-refund/Cancellations.vue"),
        },
        // {
        //   path: "admin",
        //   name: "admin",
        //   component: () => import("@/views/service/layouts/Service.vue"),
        // },
        {
          path: "aboutus",
          name: "About Us",
          component: () => import("@/views/aboutus/About.vue"),
          meta: {
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "Art",
          name: "Art",
          component: () => import("@/views/arts/Arts.vue"),
          meta: {
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "culinary",
          name: "Culinary",
          component: () => import("@/views/culinary/Culinary.vue"),
          meta: {
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "stay",
          name: "Stay",
          component: () => import("@/views/stay/Stay.vue"),
          meta: {
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "Contact-Us",
          name: "Contact Us",
          component: () => import("@/views/contact/Contact.vue"),
        },
        {
          path: "Experience",
          name: "Experience",
          component: () => import("@/views/experience/Experience.vue"),
          meta: {
            rule: "editor",
            authRequired: true,
          },
        },
      ],
    },

    //dark navbar
    // {
    //   path: "/channel",
    //   name: "maindark",
    //   component: () => import("@/main/MainDark.vue"),
    //   redirect: "channel-discussion",
    //   children: [],
    // },
  ],
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
