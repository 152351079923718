import axios from "axios";

const baseDomain = process.env.VUE_APP_BASE_URL;

const baseURL = `${baseDomain}`;
const API = axios.create({
  baseURL: baseURL,
  headers: {
    "community-name": process.env.VUE_APP_COMMUNITY_NAME,
  },
});

export default API;
