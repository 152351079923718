import axios from "axios";

const baseDomain = process.env.VUE_APP_BASE_URL;

const baseURL = `${baseDomain}`;

export default axios.create({
  headers: {
    communityName: process.env.VUE_APP_COMMUNITY_NAME,
  },
  baseURL,
});
