import RepositoryFactory from "@/apis";
const email = RepositoryFactory.email;

export default {
  sendEmail({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await email.sendEmail(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  sendEmailWithAttachment({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await email.sendEmailWithAttachment(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
