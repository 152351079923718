<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import gql from "graphql-tag";
import { mapState } from "vuex";
export default {
  data() {
    return {
      getBrandProfileData: "",
    };
  },

  async mounted() {
    this.$vs.theme({
      primary: this.$cookies.get("primaryColor"),
    });
    setTimeout(async () => {
      await this.getBrandProfile();
    }, 500);

    let payload = { projectName: process.env.VUE_APP_COMMUNITY_NAME };

    let { data } = await this.$store.dispatch("cms/getClientCms", payload);

    // await this.$store.dispatch("cms/getUser", payload);
    // const response = await this.$apollo.query({
    //   query: gql`
    //     query {
    //       allUsers {
    //         id
    //         email
    //       }
    //     }
    //   `,
    // });
    // console.log(response.data.allUsers);
    // console.log(data, "cms-check");
  },
  compunted: {
    ...mapState("cms", ["pages", "user"]),
    ...mapState("auth", { user: "user" }),
  },
  methods: {
    async getBrandProfile() {
      let payload = {
        projectId: process.env.VUE_APP_ID,
        projectName: process.env.VUE_APP_COMMUNITY_NAME,
      };
      let data = await this.$store.dispatch(
        "brandProfile/getBrandProfile",
        payload
      );
      this.getBrandProfileData = data.data;
      if (this.getBrandProfileData.length) {
        this.primaryLogo =
          `${process.env.VUE_APP_STORAGE_URL_2}` +
          this.getBrandProfileData[0].primaryLogo;

        this.secondaryLogo =
          `${process.env.VUE_APP_STORAGE_URL_2}` +
          this.getBrandProfileData[0].secondaryLogo;
        // this.$store.commit(
        //   "brandProfile/UPDATE_PRIMARY_COLOR",
        //   this.getBrandProfileData[0].primaryColor
        // );
        let data = this.$cookies.get("primaryColor");
        this.$vs.theme({
          primary: data ? data : this.getBrandProfileData[0].primaryColor,
        });

        // this.$store.commit(
        //   "brandProfile/UPDATE_SECONDARY_COLOR",
        //   this.getBrandProfileData[0].secondaryColor
        // );
        this.$vs.theme({
          secondary: this.getBrandProfileData[0].secondaryColor,
        });

        // this.$store.commit(
        //   "brandProfile/UPDATE_TERTIARY_COLOR",
        //   this.getBrandProfileData[0].tertiaryColor
        // );
        this.$vs.theme({
          tertiary: this.getBrandProfileData[0].tertiaryColor,
        });
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
