// import axios from "axios";
// import store from "@/store";
// import router from "@/router";
// import { Encript, Decrypt } from "@/helpers/CryptoJS";
// // You can use your own logic to set your local or production domain

// const baseDomain = process.env.VUE_APP_BASE_URL;
// const baseURL = `${baseDomain}sw`;

// const userInfoLocalStorage = localStorage.getItem("token");

// const API = axios.create({
//   baseURL: baseURL,
//   headers: {
//     "project-suite": "Ecommerce",
//     "community-name": process.env.VUE_APP_COMMUNITY_NAME,
//   },
// });

// API.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     // console.log(config.headers["Content-Type"]);
//     if (config.headers["Content-Type"] == "multipart/form-data") {
//       let FormData = require("form-data");

//       let data = new FormData();
//       data.append(
//         "data",
//         process.env.VUE_APP_ENCRYPTION != 0
//           ? Encript(JSON.stringify(config.data.data))
//           : JSON.stringify(config.data.data)
//       );
//       for (const key in config.data) {
//         if (key != "data") {
//           data.append(key, config.data[key]);
//         }
//       }
//       // if (config.data["media"]) {
//       //   data.append("media", config.data.media);
//       // }
//       // if (config.data["profilepic"]) {
//       //   data.append("profilepic", config.data.profilepic);
//       // }
//       // if (config.data["image"]) {
//       //   data.append("image", config.data.image);
//       // }
//       // if (config.data["file"]) {
//       //   data.append("file", config.data.image);
//       // }
//       // if (config.data["comp_logo"]) {
//       //   data.append("comp_logo", config.data.comp_logo);
//       // }
//       // if (config.data["pitchDoc"]) {
//       //   data.append("pitchDoc", config.data.pitchDoc);
//       // }
//       // let formObj = {};
//       // for (var pair of data.entries()) {
//       //   formObj[pair[0]] = pair[1];
//       // }

//       config.data = data;

//       // {
//       //   data: Encript(JSON.stringify(config.data.data)),
//       //   media: config.data.media,
//       // };
//     } else if (config.data) {
//       // console.log(JSON.stringify(Object.fromEntries(config.data)));
//       // console.log(Encript(config.data));
//       config.data = {
//         data:
//           process.env.VUE_APP_ENCRYPTION != 0
//             ? Encript(JSON.stringify(config.data))
//             : JSON.stringify(config.data),
//       };
//       // config.data = {
//       //   data: Encript(JSON.stringify(Object.fromEntries(config.data))),
//       // };
//     }
//     if (token) {
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     return config;
//   },

//   (error) => Promise.reject(error)
// );
// API.interceptors.response.use(
//   (response) => {
//     if (response) {
//       if (Object.keys(response.data.data).length) {
//         response.data.data = Decrypt(response.data.data);
//       }
//     }
//     return response;
//   },
//   async function (error) {
//     if (
//       error.response.data.message.includes("Unauthorized") &&
//       error.response.data.code == 401
//     ) {
//       router.push({ name: "Home" });

//       // store.commit("UI/OPEN_LOGIN_POP", true);
//       alert("SESSION EXPIRE PLEASE LOGIN AGAIN");
//       await store.dispatch("auth/logout");
//     }
//     if (
//       error.request.responseURL.split("/")[5] == "logout" &&
//       error.response.data.code != 200
//     )
//       try {
//         router.push({ name: "Home" });

//         // store.commit("UI/OPEN_LOGIN_POP", true);
//         alert("SESSION EXPIRE PLEASE LOGIN AGAIN");
//         await store.dispatch("auth/logout");
//       } catch (e) {}
//     return Promise.reject(error);
//   }
//   // (error) => Promise.reject(error)
// );

// export default API;

import axios from "axios";

// You can use your own logic to set your local or production domain

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}sw`;

const userInfoLocalStorage = localStorage.getItem("token");

const API = axios.create({
  baseURL: baseURL,
  headers: {
    "community-name": process.env.VUE_APP_COMMUNITY_NAME,
  },
});
const token = localStorage.getItem("token");
API.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

export default API;
