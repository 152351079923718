import Repository from "./Repository";
const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const REGISTER = "/signupWithPassword";
const GENERATE_OTP = "/GenerateOTP";
const VERIFYOTP = "/VerifyOTP";
const LOGIN = "/loginWithPassword";
const LOGOUT = "/logout";
const FORGOT_PASSWORD = "/forgotPassword";
const UPDATEPROFILE = "/updateprofile";
const GETSINGLE_USER = "/getsingleUser";
const LOGINWITHOTP = "/loginWithOTP";
const GETALLUSER = "/getAllUser";
const KYCCHECKED = "/kycChecked";
const AADHARVERIFY = "/aadharChecked";
const AADHARCHECK = "/aadharProgress";
const DELETE = "/delete";

export default {
  signupWithPassword(payload) {
    return Repository.post(transformRoute(REGISTER), payload);
  },
  generateOTP(payload) {
    return Repository.post(transformRoute(GENERATE_OTP), payload);
  },
  VerifyOTP(payload) {
    return Repository.post(transformRoute(VERIFYOTP), payload);
  },
  loginWithPassword(payload) {
    return Repository.post(transformRoute(LOGIN), payload);
  },
  loginWithOtp(payload) {
    return Repository.post(transformRoute(LOGINWITHOTP), payload);
  },
  logout(payload) {
    return Repository.post(transformRoute(LOGOUT), payload);
  },
  forgotPassword(payload) {
    return Repository.post(transformRoute(FORGOT_PASSWORD), payload);
  },
  aadharChecked(payload) {
    return Repository.post(transformRoute(AADHARCHECK), payload);
  },
  aadharVerify(payload) {
    return Repository.post(transformRoute(AADHARVERIFY), payload);
  },
  updateProfile(payload) {
    return Repository.post(transformRoute(UPDATEPROFILE), payload);
  },
  getsingleProfile(payload) {
    return Repository.post(transformRoute(GETSINGLE_USER), payload);
  },
  getallUser(payload) {
    return Repository.post(transformRoute(GETALLUSER), payload);
  },
  kycchecked(payload) {
    return Repository.post(transformRoute(KYCCHECKED), payload);
  },
  delete(payload) {
    return Repository.post(transformRoute(DELETE), payload);
  },
};

const transformRoute = (route) => {
  return `/auth${route}`;
};
