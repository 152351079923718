import Repository from "./Repository4";

const GET = "get";
const EDIT = "update";
const CREATE = "create";
const GET_BUSINESS_SUITES = process.env.VUE_APP_BRAINED_API_URL;
const SUITES = "Get";
export default {
  getBrandProfile(payload) {
    return Repository.post(transformRoute(GET), payload);
  },
  uploadBrandProfile(payload) {
    return Repository.post(transformRoute(EDIT), payload);
  },
  createBrandProfile(payload) {
    return Repository.post(transformRoute(CREATE), payload);
  },
  getBusinessSuites() {
    return Repository.post(`${GET_BUSINESS_SUITES}brained/suites/view_suites`);
  },
  getSuites(payload) {
    return Repository.post(transformRoute2(SUITES), payload);
  },
};

const transformRoute = (route) => {
  return `/miniStore/brandProfile/${route}`;
};
const transformRoute2 = (route) => {
  return `/masters/suite/${route}`;
};
